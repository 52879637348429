/* eslint-disable */
import {
    BaseAPI
} from './BaseAPI';

export class DataService extends BaseAPI {
    constructor(config) {
        super(config);
    }

    async getDataSets(sampleTypes, dataSources, suitabilityIndicators, samplingApproaches) {
         const query =
         `
            PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
            PREFIX fwgw: <http://www.freshwatergateway.org.uk/vocab/>
            PREFIX schema: <https://schema.org/>
            PREFIX dct: <http://purl.org/dc/terms/>

            select * where {
                ?id rdf:type fwgw:DataSet .
                ?id fwgw:hasSampleType ?sampleType .
                ?id fwgw:hasSamplingApproach ?samplingApproach .
                ?id fwgw:hasSuitabilityIndicator ?suitabilityIndicator .
                ?id fwgw:hasSuitabilityIndicator ?suitabilityIndicator .
                ?id schema:application ?application .
                ?id fwgw:providedBy ?organisation .
                ?id schema:startDate ?startDate .
                ?id schema:endDate ?endDate .
                ?id dct:title ?title.
                optional { ?id schema:url ?url .}
            }

         `;
         var responses = await this.query(query);
         let newResponses = [];
         for (const response of responses) {
            if (!samplingApproaches.includes(response.samplingApproach)) continue;
            if (!dataSources.includes(response.organisation)) continue;
            if (!suitabilityIndicators.includes(response.suitabilityIndicator)) continue;
            if (!sampleTypes.includes(response.sampleType)) continue;
            newResponses.push(response);
         }  

        return newResponses;
    }


    async getMonitoringPointMetaData(monitoringPointId) {
        return {} // we need to decide on the format for this
    }

    async getData(monitoringPointId, datasetId) {
        return [
            {
                x: 'yyyy-MM-dd HH:mm:ss',
                y: 10.0
            }
        ]
    }
};


