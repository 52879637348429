/* eslint-disable */
import {
    BaseAPI
} from './BaseAPI.js';

export class ParameterService extends BaseAPI {
    constructor() {
        super();
    }

    async getDataSources() {
        const query =`
           PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
            PREFIX fwgw: <http://www.freshwatergateway.org.uk/vocab/>
            PREFIX schema: <https://schema.org/>
            PREFIX dct: <http://purl.org/dc/terms/>

            select ?s ?title where {
                ?s rdf:type schema:Organisation .
                ?s dct:title ?title .
            }
        `;

        var responses = await this.query(query);
        var results = [];
        for (let response of responses) results.push({title:response.title,id:response.s})
        return results;
    } 

    async getSamplingApproaches() {
        const query =`
            PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
            PREFIX fwgw: <http://www.freshwatergateway.org.uk/vocab/>
            PREFIX schema: <https://schema.org/>
            PREFIX dct: <http://purl.org/dc/terms/>

            select ?title ?id where {
                ?id rdf:type fwgw:SamplingApproach .
                ?id dct:title ?title .
            }
        `;

        var responses = await this.query(query);
        var results = [];
        for (let response of responses) results.push({title:response.title, id:response.id})
        return results;
    }

    async getSuitabilityIndicators() {
          const query =`
            PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
            PREFIX fwgw: <http://www.freshwatergateway.org.uk/vocab/>
            PREFIX schema: <https://schema.org/>
            PREFIX dct: <http://purl.org/dc/terms/>

            select ?title ?s where {
                ?s rdf:type fwgw:SuitabilityIndicator .
                ?s dct:title ?title .
            }
        `;

        var responses = await this.query(query);
        var results = [];
        for (let response of responses) results.push({title:response.title,id:response.s})
        return results;
    }

    async getSampleTypes() {
         const query =`
            PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
            PREFIX fwgw: <http://www.freshwatergateway.org.uk/vocab/>
            PREFIX schema: <https://schema.org/>
            PREFIX dct: <http://purl.org/dc/terms/>

            select ?title ?part ?s where {
                ?s rdf:type fwgw:SampleType .
                ?s dct:title ?title .
                ?s dct:hasPart ?part .
                FILTER NOT EXISTS { ?y dct:hasPart ?s . }
            }
        `;

        var responses = await this.query(query);

        const query2 =`
            PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
            PREFIX fwgw: <http://www.freshwatergateway.org.uk/vocab/>
            PREFIX schema: <https://schema.org/>
            PREFIX dct: <http://purl.org/dc/terms/>

            select ?s ?title ?part  where {
                ?s rdf:type fwgw:SampleType .
                ?s dct:title ?title .
                optional { ?s dct:hasPart ?part .}
            }
        `;

        var responses2 = await this.query(query2);


        var finalResults = [];

        for (let response of responses) {
           let responseObject = finalResults.find((e)=> e.label==response.title);
           if (responseObject == undefined) {
            responseObject = {label:response.title, enaled:false, id:response.s,children:[]};
            finalResults.push(responseObject);
          }
           this.populatePart(responseObject.children,response.part,responses2);
        }
        return finalResults;
    }  

     populatePart(obj, partId,responses) {
        for (let response of responses) {
            if (response.s == partId) {
                let responseObject = obj.find((e)=> e.label==response.title);
                 if (responseObject == undefined) {
                    responseObject = {label:response.title, enaled:false, id:response.s,children:[]};
                    obj.push(responseObject);
                }
                if ('part' in response) this.populatePart(responseObject.children,response.part,responses);
            }
        }
    }

}


