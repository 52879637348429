<template>
  <div class="search-results-container" :class="{ 'collapsed': isCollapsed }">
    <div class="search-results-header">
      <h2 class="search-results-title" :class="{ 'hidden': isCollapsed }">Search results</h2>
      <div class="toggle-button" @click="toggleCollapse">
        <v-icon>{{ isCollapsed ? 'mdi-chevron-right' : 'mdi-chevron-left' }}</v-icon>
      </div>
    </div>
    <div class="search-results-content" v-show="!isCollapsed">
      <div v-for="result in results" :key="result.id" class="result-card">
        <div class="result-header">
          <div class="icon-container" :class="result.type">
            <v-icon class="icon">{{ getIcon(result.type) }}</v-icon>
          </div>
          <div class="result-info">
            <h4 class="result-title">{{ result.title }}</h4>
            <p class="result-location">{{ result.location }}</p>
          </div>
        </div>
        <div class="result-details">
          <div class="detail-item">
            <v-icon class="detail-icon fish-icon">mdi-fish</v-icon>
            <span class="detail-text">Dataset: {{ result.application.split("/")[result.application.split("/").length-1] }}</span>
          </div>
          <div class="detail-item">
            <v-icon class="detail-icon data-icon">mdi-database</v-icon>
            <span class="detail-text">Data from: {{ result.organisation.split("/")[result.organisation.split("/").length-1] }}</span>
          </div>
        </div>
        <div class="result-buttons">
          <button v-if="result.application.endsWith('GEOJSON') && currentGeoJSONDataset==result.url" @click="currentGeoJSONDataset=''; emitter.emit('hidegeojson', result.url)" class="action-btn see-graph-btn">
            <v-icon class="button-icon">mdi-map-outline</v-icon>
            Hide Results
          </button>
           <button v-else-if="result.application.endsWith('GEOJSON')" @click="currentGeoJSONDataset=result.url; emitter.emit('viewgeojson', {url:result.url, type: result.application})" class="action-btn see-graph-btn">
            <v-icon class="button-icon">mdi-map-outline</v-icon>
            See Results
          </button>

           <button v-else @click="$emit('see-graph', result.id)" class="action-btn see-graph-btn">
            <v-icon class="button-icon">mdi-chart-line</v-icon>
            See Graph
          </button>
          <button @click="$emit('see-metadata', result.id)" class="action-btn see-metadata-btn">
            <v-icon class="button-icon">mdi-information-outline</v-icon>
            See Metadata
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {ref,onMounted,inject} from 'vue';
  import useEmitter from '@/plugins/useEmitter'

  const DataService = inject('DataService');
  
  const currentGeoJSONDataset = ref("");
  const results = ref([]);
  const emitter = useEmitter()
  const isCollapsed = ref(true);
  const samplingApproaches = ref([]);
  const dataSources = ref([]);
  const suitabilityIndicators = ref([]);
  const timeIntervals = ref([]);
  const sampleTypes = ref([]);

  const populateResults = async function () {
    results.value = await DataService.getDataSets(sampleTypes.value, dataSources.value, suitabilityIndicators.value, samplingApproaches.value,timeIntervals.value);
    if (results.value.length > 0) isCollapsed.value=false;
    else isCollapsed.value=true;
  }


  const getIcon = function(type) {
    const icons = {
      river: 'mdi-waves',
      catchment: 'mdi-map',
      samplingPoint: 'mdi-map-marker',
    };
    return icons[type] || 'mdi-help-circle';
  }

  const toggleCollapse = function() {
    isCollapsed.value = !isCollapsed.value;
  }

  onMounted(() => { 
      emitter.on("sampleType", values => {
        sampleTypes.value=values.map(x => x.id);
        populateResults();
      });

      emitter.on("timeinterval", values => {
        console.log(values);
        timeIntervals.value=values;
        populateResults();
      });

      emitter.on("samplingapproach", value => {
        if (value.status) samplingApproaches.value.push(value.id);
        else samplingApproaches.value.splice(samplingApproaches.value.indexOf(value.id),1);
        populateResults();
      });

      emitter.on("datasource", value => {
        if (value.status) dataSources.value.push(value.id);
        else dataSources.value.splice(dataSources.value.indexOf(value.id),1);
        populateResults();
      });

       emitter.on("suitabilityindicator", value => {
        if (value.status) suitabilityIndicators.value.push(value.id);
        else suitabilityIndicators.value.splice(suitabilityIndicators.value.indexOf(value.id),1);
        populateResults();
      });
  })
</script>

<style scoped>
.search-results-container {
  position: absolute;
  top: 80px;
  width:250px;
  right: 0;
  background-color: #f0f4f8;
  font-family: Arial, sans-serif;
  transition: all 0.3s ease;
  height: 90%;
  display: flex;
  flex-direction: column;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
}

.search-results-container.collapsed {
  width: 60px;
  height: 60px;
}

.search-results-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #4a90e2;
  padding: 12px 16px;
  color: #ffffff;
  height: 60px;
}

.search-results-title {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: opacity 0.3s ease;
}

.search-results-title.hidden {
  opacity: 0;
}

.toggle-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  transition: background-color 0.2s;
}

.toggle-button:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.search-results-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 16px;
}

.result-card {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.result-header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.icon-container {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
}

.icon-container.river {
  background-color: #e3f2fd;
}

.icon-container.catchment {
  background-color: #e8f5e9;
}

.icon-container.samplingPoint {
  background-color: #e74c3c;
}

.icon {
  font-size: 20px;
}

.river .icon {
  color: #1976d2;
}

.catchment .icon {
  color: #43a047;
}

.samplingPoint .icon {
  color: #ffffff;
}

.result-info {
  flex-grow: 1;
}

.result-title {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  color: #2d3748;
}

.result-location {
  font-size: 14px;
  color: #718096;
  margin: 2px 0 0 0;
}

.result-details {
  margin-bottom: 12px;
}

.detail-item {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.detail-icon {
  font-size: 18px;
  margin-right: 8px;
}

.fish-icon {
  color: #3498db;
}

.data-icon {
  color: #9b59b6;
}

.detail-text {
  font-size: 14px;
  color: #4a5568;
}

.result-buttons {
  justify-content: space-between;
}

.action-btn {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.see-graph-btn {
  background-color: #3498db;
  color: #ffffff;
  margin-right: 8px;
}

.see-metadata-btn {
  background-color: #e2e8f0;
  color: #4a5568;
}

.see-graph-btn:hover {
  background-color: #2980b9;
}

.see-metadata-btn:hover {
  background-color: #cbd5e0;
}

.button-icon {
  margin-right: 6px;
  font-size: 18px;
}
</style>