<template>
  <div class="map-container">
    <div class="search-overlay">
      <div class="search-container">
        <div class="search-icon">
          <v-icon>mdi-magnify</v-icon>
        </div>
        <input
          type="text"
          class="search-input"
          placeholder="Search for locations in Wales"
          v-model="searchQuery"
          @input="handleSearch"
          @focus="showSuggestions = true"
          @blur="hideSuggestionsDelayed"
          @keyup.enter="handleEnter"
        >
        <div v-if="showSuggestions && filteredSuggestions.length" class="suggestions-container">
          <div
            v-for="suggestion in filteredSuggestions"
            :key="suggestion.title"
            class="suggestion-item"
            @mousedown.prevent="selectSuggestion(suggestion)"
          >
            {{ suggestion.title }}
          </div>
        </div>
      </div>
    </div>
    <div id="loader" v-if='loaderVisible'></div>
    <div id="map" class="map"></div>
  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { ref, onMounted, onUnmounted, watch, inject } from 'vue';
import useEmitter from '@/plugins/useEmitter'


export default {
  name: 'MapComponent',
  props: {
    showCatchments: Boolean,
    showRivers: Boolean,
    showSubCatchments: Boolean,
  },
  setup(props, { emit }) {
    const loaderVisible = ref(false);
    const GISService = inject('GISService');
    const map = ref(null);
    const mapLoaded = ref(false);
    const searchQuery = ref('');
    const showSuggestions = ref(false);
    const filteredSuggestions = ref([]);
    const allFeatures = ref([]);
    const emitter = useEmitter();


    emitter.on("viewgeojson", async (value) => {
        loaderVisible.value=true;
        if (map.value.getLayer('data-layer-line') != undefined) map.value.removeLayer('data-layer-line');
        if (map.value.getLayer('data-layer') != undefined) map.value.removeLayer('data-layer');
        if (map.value.getSource('data') != undefined) map.value.removeSource('data');
        
        var bounds = new mapboxgl.LngLatBounds();
        const response = await (await fetch(value.url)).json();
        console.log("Loaded");
        
        response.features.forEach(function(feature) {
          let coordinates = feature.geometry.coordinates;
          while (coordinates.length!=2) coordinates=coordinates[0]
          bounds.extend(coordinates);
        });
        
        map.value.addSource('data', {
            type: 'geojson',
            data: response
        });
        if (value.type.endsWith("AREAGEOJSON")) {
          map.value.addLayer({
              'id': 'data-layer',
              'type': 'fill',
              'source': 'data',
              'paint': {
                "fill-color":'red',
                'fill-opacity': 0.3,
              }
          });

          map.value.addLayer({
            id: `data-layer-line`,
            type: 'line',
            source: 'data',
            paint: {
              'line-color': 'red',
              'line-width': 1,
            }
          });

        } else {
           map.value.addLayer({
              'id': 'data-layer',
              'type': 'circle',
              'source': 'data',
              'paint': {
                  'circle-radius': 4,
                  'circle-stroke-width': 2,
                  'circle-color': 'red',
                  'circle-stroke-color': 'white'
              }
          });
        }

        map.value.fitBounds(bounds);
        map.value.on('click', 'data-layer', function (e) {
          var coordinates = e.features[0].geometry.coordinates;
          try {
            while (coordinates.length!=2) coordinates=coordinates[0]
            var html="<table>";
            for (const [key, value] of Object.entries(e.features[0].properties)) {
              html+="<tr><th>"+key+":</th><td>"+value+"</td>";
            }
            html+="</table>";
            new mapboxgl.Popup().setLngLat(coordinates).setMaxWidth("700px").setHTML(html).addTo(map.value);
          } catch (e) {
            console.error("ERROR GETTING COORDS");
          }
        });

        loaderVisible.value=false;
    });

    emitter.on("hidegeojson", () => {
        if (map.value.getLayer('data-layer-line') != undefined) map.value.removeLayer('data-layer-line');
        if (map.value.getLayer('data-layer') != undefined) map.value.removeLayer('data-layer');
        if (map.value.getSource('data') != undefined) map.value.removeSource('data');
    });


    const WALES_CENTER = [-3.7837, 52.1307];
    const DEFAULT_ZOOM = 7;

    onMounted(() => {
      initializeMap();
      loadFeatures();
    });

    onUnmounted(() => {
      if (map.value) {
        map.value.remove();
      }
    });

    const initializeMap = () => {
      console.log('Initializing map');
      mapboxgl.accessToken = 'pk.eyJ1IjoiYW5kcmVpaDE5NCIsImEiOiJjbGdob25kbDAwbnB6M2l0MWpxNnBvczFvIn0.Z8UWHTSl7KxL56wrT4APXQ';

     if (mapboxgl.getRTLTextPluginStatus() === 'unavailable') {
        mapboxgl.setRTLTextPlugin(
        'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js',
        null,
        true // Lazy load the plugin
        );
      }

      map.value = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/light-v10',
        center: WALES_CENTER,
        zoom: DEFAULT_ZOOM,
      });

      map.value.addControl(new mapboxgl.NavigationControl(), 'top-left');

      class ResetControl {
        onAdd(map) {
          this._map = map;
          this._container = document.createElement('div');
          this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
          this._container.innerHTML = '<button class="mapboxgl-ctrl-icon" type="button" title="Reset map"><span class="mapboxgl-ctrl-icon" aria-hidden="true">🔄</span></button>';
          this._container.onclick = () => {
            map.flyTo({ center: WALES_CENTER, zoom: DEFAULT_ZOOM });
          };
          return this._container;
        }

        onRemove() {
          this._container.parentNode.removeChild(this._container);
          this._map = undefined;
        }
      }

      map.value.addControl(new ResetControl(), 'top-left');

      map.value.on('load', () => {
        mapLoaded.value = true;
        updateSource('catchments',true);
        toggleLayerVisibility('catchments-layer', true);
        toggleLayerVisibility('catchments-outline', true);
        addTooltips();
      });

      map.value.on('error', (e) => {
        console.error('Mapbox GL error:', e);
      });
    };

    const addLayer = (name, type, color, visible) => {
      if (!map.value.getSource(name)) {
        map.value.addSource(name, {
          type: 'geojson',
          dynamic: true,
          data: {
            type: 'FeatureCollection',
            features: []
          }
        });

        if (type === 'fill') {
          map.value.addLayer({
            id: `${name}-layer`,
            type: 'fill',
            source: name,
            paint: {
              'fill-color': color,
              'fill-opacity': 0.3,
            },
            layout: {
              visibility: visible ? 'visible' : 'none',
            },
          });

          map.value.addLayer({
            id: `${name}-outline`,
            type: 'line',
            source: name,
            paint: {
              'line-color': color,
              'line-width': 1,
            },
            layout: {
              visibility: visible ? 'visible' : 'none',
            },
          });
        } else if (type === 'line') {
          map.value.addLayer({
            id: `${name}-layer`,
            type: 'line',
            source: name,
            paint: {
              'line-color': color,
              'line-width': 2,
            },
            layout: {
              visibility: visible ? 'visible' : 'none',
            },
          });
        }
      }
    };

    const renderFeature = (item,id,sourceName,geometryType) => {
        let feature = null;
        try {
          let coordinates;
          if (geometryType === 'Polygon') {
            coordinates = [item.geo];
            if (coordinates[0][0][0] !== coordinates[0][coordinates[0].length - 1][0] ||
                coordinates[0][0][1] !== coordinates[0][coordinates[0].length - 1][1]) {
              coordinates[0].push(coordinates[0][0]);
            }
          } else {
            coordinates = item.geo;
          }
          feature = {
            id : id,
            type: 'Feature',
            properties: { title: item.title },
            geometry: {
              type: geometryType,
              coordinates: coordinates
            }
          };

        } catch (error) {
          console.error(`Error processing item: ${item.title}`, error);
          return null;
        }

        if (feature == null) return;
        try {
          map.value.getSource(sourceName).updateData({
            type: 'FeatureCollection',
            features: [feature]
          });
        } catch (error) {
          console.error(`Error updating source ${sourceName}:`, error);
        }
    }

    const updateSource = (sourceName, toShow) => {
      if (!mapLoaded.value || !map.value ) {
        console.warn(`Source ${sourceName} not ready or map not loaded`);
        return;
      }

      if (toShow==true && !map.value.getSource(sourceName)) {

        if (sourceName=='catchments') {
          addLayer('catchments', 'fill', '#088', props.showCatchments);
          GISService.getCatchments((item,id) => renderFeature(item,id,'catchments','Polygon'));
        } else if (sourceName == 'rivers') {
          addLayer('rivers', 'line', '#4CAF50', props.showRivers);
          GISService.getRivers((item,id) => renderFeature(item,id,'rivers','MultiLineString'));
        } else if (sourceName == 'sub-catchments') {
          addLayer('sub-catchments', 'fill', '#FF9800', props.showSubCatchments);
          GISService.getSubCatchments((item,id) => renderFeature(item,id,'sub-catchments','Polygon'));
        }

        console.log(`Updating source: ${sourceName}`);
      
      }
    };


    const addTooltips = () => {
      const addTooltipToLayer = (layerId) => {
        const popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false
        });

        map.value.on('mousemove', layerId, (e) => {
          if (!e.features || e.features.length === 0) return;

          map.value.getCanvas().style.cursor = 'pointer';
          const feature = e.features[0];
          const title = feature.properties.title;

          let coordinates;
          if (feature.geometry.type === 'Polygon') {
            coordinates = feature.geometry.coordinates[0][0];
          } else if (feature.geometry.type === 'MultiLineString') {
            coordinates = feature.geometry.coordinates[0][0];
          } else if (feature.geometry.type === 'LineString') {
            coordinates = feature.geometry.coordinates[0];
          } else {
            console.log(feature.geometry.type);
            coordinates = feature.geometry.coordinates;
          }

          if (Array.isArray(coordinates) && coordinates.length === 2 &&
              typeof coordinates[0] === 'number' && typeof coordinates[1] === 'number') {

            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
              coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }
            popup.setLngLat(coordinates).setHTML(title.replace("-"," ")).addTo(map.value);
          }
        });

        map.value.on('mouseleave', layerId, () => {
          map.value.getCanvas().style.cursor = '';
          popup.remove();
        });
      };

      addTooltipToLayer('catchments-layer');
      addTooltipToLayer('rivers-layer');
      addTooltipToLayer('sub-catchments-layer');
    };

    watch(() => props.showCatchments, (show) => {
      updateSource('catchments',show);
      toggleLayerVisibility('catchments-layer', show);
      toggleLayerVisibility('catchments-outline', show);
    });

    watch(() => props.showRivers, (show) => {
      updateSource('rivers',show);
      toggleLayerVisibility('rivers-layer', show);
    });

    watch(() => props.showSubCatchments, (show) => {
      updateSource('sub-catchments',show);
      toggleLayerVisibility('sub-catchments-layer', show);
      toggleLayerVisibility('sub-catchments-outline', show);
    });

    const toggleLayerVisibility = (layerId, isVisible) => {
      if (mapLoaded.value && map.value.getLayer(layerId)) {
        map.value.setLayoutProperty(layerId, 'visibility', isVisible ? 'visible' : 'none');
      } else {
        console.warn(`Layer ${layerId} not found or map not loaded`);
      }
    };

    const loadFeatures = () => {
      GISService.getCatchments((item, id) => allFeatures.value.push({...item, id, type: 'catchment'}));
      GISService.getRivers((item, id) => allFeatures.value.push({...item, id, type: 'river'}));
      GISService.getSubCatchments((item, id) => allFeatures.value.push({...item, id, type: 'subCatchment'}));
    };

    const handleSearch = () => {
      filteredSuggestions.value = allFeatures.value.filter((asset) =>
        asset.title.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    };

    const selectSuggestion = (suggestion) => {
      searchQuery.value = suggestion.title;
      showSuggestions.value = false;
      focusOnShape(suggestion);
    };

    const focusOnShape = (suggestion) => {
      if (mapLoaded.value) {
        const coordinates = suggestion.geo;
        const bounds = coordinates.reduce(
          (bounds, coord) => bounds.extend(coord),
          new mapboxgl.LngLatBounds(coordinates[0], coordinates[0])
        );
        map.value.fitBounds(bounds, { padding: 50 });
      }
    };

    const hideSuggestionsDelayed = () => {
      setTimeout(() => {
        showSuggestions.value = false;
      }, 200);
    };

    const handleEnter = () => {
      if (filteredSuggestions.value.length > 0) {
        emit('filteredResults', filteredSuggestions.value);
      }
    };

    return {
      searchQuery,
      showSuggestions,
      filteredSuggestions,
      handleSearch,
      selectSuggestion,
      hideSuggestionsDelayed,
      handleEnter,
      loaderVisible
    };
  },
};
</script>

<style scoped>
.map-container {
  position: relative;
  width: 100%;
  height: 100vh;
}

.search-overlay {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  pointer-events: none;
}

.search-container {
  position: relative;
  background-color: #ffffff;
  padding: 8px 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 400px;
  height: 64px;
  display: flex;
  align-items: center;
  pointer-events: auto;
}

.search-icon {
  color: #757575;
  margin-right: 12px;
}

.search-input {
  border: none;
  outline: none;
  font-size: 16px;
  flex-grow: 1;
  background-color: transparent;
}

.suggestions-container {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 8px 8px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1;
}

.suggestion-item {
  padding: 8px 16px;
  cursor: pointer;
}

.suggestion-item:hover {
  background-color: #f5f5f5;
}

.map {
  width: 100%;
  height: 100%;
}

.load-more-container {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}

.load-more-container button {
  height: 32px;
  padding: 0 15px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.load-more-container button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.mapboxgl-ctrl-icon {
  font-size: 20px;
  line-height: 30px;
}

 #loader {
      border: 16px solid #f3f3f3;
      /* Light grey */
      border-top: 16px solid #93E9BE;
      /* Blue */
      border-radius: 50%;
      width: 120px;
      height: 120px;
      animation: spin 2s linear infinite;
      position: fixed;
      top: 50%;
      left: 50%;
      margin-top: -100px;
      margin-left: -100px;
      z-index: 2;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

</style>